import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { jwtDecode } from "jwt-decode";
import { BaseService } from './base.service';
import { IJWTUser, ResponseJson } from '../../shared/models/interfaces';
import { LOCAL_STORAGE_KEYS } from '../../shared/models/enum';
import { PermissionsService } from './permissions.service';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService extends BaseService {

  isLoading: Subject<boolean> = new Subject<boolean>();
  currentUser: BehaviorSubject<IJWTUser | null> = new BehaviorSubject<IJWTUser | null>(null);
  apiUrl = environment.apiUrl;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _permissionsService: PermissionsService,
    protected override http: HttpClient,
  ) {
    super(http);
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    if (token) {
      const user = jwtDecode(token);
      this.currentUser.next(user as IJWTUser);
    }
  }

  showLoader() {
    this.isLoading.next(true);
  }

  hideLoader() {
    this.isLoading.next(false);
  }

  storeUserToken(data: string) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, data);
    const user = jwtDecode(data);
    this.currentUser.next(user as IJWTUser);
    return user;
  }

  getOtp(contactNumber: string) {
    return this.get<ResponseJson<any>>(this.apiUrl + '/public/auth/generate-otp', { contactNumber });
  }

  verifyOtp(contactNumber: string, otp: string) {
    return this.post<ResponseJson<any>>(this.apiUrl + '/public/auth/verify-otp', { contactNumber, otp });
  }

  getUserProjects(contactNumber: string, searchTerm: string) {
    return this.get<ResponseJson<any>>(this.apiUrl + '/project-auth', { contactNumber, searchTerm });
  }

  login(contactNumber: string, project: string) {
    this.get<ResponseJson<any>>(this.apiUrl + '/project-auth/token', { contactNumber, project }).subscribe(res => {
      if (res.success) {
        this.storeUserToken(res.data);
        const returnUrl = this._route.snapshot.queryParams['returnUrl'];
        if (returnUrl) {
          this._router.navigate([returnUrl]);
        } else {
          this._router.navigate(['dashboard']);
        }
      }
    })
  }

  switchAccount(contactNumber: string, project: string) {
    return this.get<ResponseJson<any>>(this.apiUrl + '/project-auth/token', { contactNumber, project }).subscribe(res => {
      if (res.success) {
        this.storeUserToken(res.data);
        this._router.navigate(['/']);
        setTimeout(() => {
          location.reload();
        }, 100)
      }
    })
  }

  logOutUser() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
    this._permissionsService.userPermissions.set(null);
    this.currentUser.next(null);
    this._router.navigate(['/']);
  }

}